import {StyleSheet} from 'aphrodite';
import theme from '../../themes/themeConstants.ts'

export default StyleSheet.create({
    navbar: {
        height: 80,
        width: "100%",
        fontSize: 24,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '15px 25px 15px 25px',
    },
    navbarContainer: {
        zIndex: 11,
        height: 80,
        display: "flex",
        width: "100%",
        justifyContent: "center",
        backgroundColor: theme.colors.primary,
        boxShadow: "rgb(0 0 0 / 23%) 1px 0px 12px 0px",
        position: "relative",
    }
});
