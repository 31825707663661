import { StyleSheet } from "aphrodite";

export default StyleSheet.create({
    card: {
        width: "20rem",
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f8f9fa",
    },
});
