import { useTranslation } from "react-i18next";
 
const useTranslate = (resources: string | string[]) => {
    const { t, i18n } = useTranslation(resources);
 
    const keyExists = (key: string) => {
        return i18n.exists(key, { ns: resources });
    };
 
    const translate = (key: string) => {
        const defaultKey = `default.${key}`;
        return keyExists(defaultKey) ? t(defaultKey) : key;
    };
 
    return { t: translate };
};
 
export default useTranslate;