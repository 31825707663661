import {ApiJsonResponse, UserCredentials} from "../../Types";
import {TokenResponse} from "../../Types";
import HttpClient from "../../Utils/HttpClient.ts";
import {EmailConfirmationRequest, UserRegistrationDetails, UserRegistrationResponse, UserType} from "../../Types/UserTypes.ts";

const authenticateUser = async (credentials: UserCredentials): Promise<ApiJsonResponse<UserType>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<UserType>>>('/Auth/Token', {body: credentials})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const registerUser = async (registrationDetails: UserRegistrationDetails): Promise<ApiJsonResponse<UserRegistrationResponse>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<UserRegistrationResponse>>>('/Auth/Register', {body: registrationDetails})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const refreshAccessToken = async (accessToken: string, refreshToken: string): Promise<ApiJsonResponse<TokenResponse>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<TokenResponse>>>('/Auth/Token/Refresh', {
            body: {
                accessToken: accessToken,
                refreshToken: refreshToken,
            }
        })
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const confirmUserEmail = async (request: EmailConfirmationRequest): Promise<ApiJsonResponse<string>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<string>>>('/Auth/ConfirmEmail', {body: request})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
export {authenticateUser, registerUser, refreshAccessToken, confirmUserEmail}
