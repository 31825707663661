import {useAppSelector} from "../../State/hooks.ts";
import User from "../../Models/User/User.ts";
import {useEffect, useState} from "react";
import Application from "../../Models/Application/Application.ts";
import {css} from "aphrodite";
import styles from './styles.tsx';
import {ApplicationItem} from "./components/ApplicationItem/ApplicationItem.tsx";
import Button from "react-bootstrap/Button";
import useTranslate from "../../Hooks/useTranslate.ts";
import {useNavigate} from "react-router-dom";
import {Notify} from "../../Utils/Notify.ts";
import {Container, OverlayTrigger, Popover} from "react-bootstrap";

const HomePage = () => {
    // const user = useAppSelector(User.selectUser);
    const applications = useAppSelector(Application.selectApplications);
    const applicationsIsLoading = useAppSelector(Application.selectApplicationsIsLoading);
    const applicationIsInProgress = useAppSelector(Application.selectApplicationIsInProgress)
    const isHttpClientReady = useAppSelector(User.selectHttpClientIsReady);
    const [fetchApplicationsTrigger, setFetchApplicationsTrigger] = useState<boolean>(false);
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    useEffect(() => {
        if (!applicationsIsLoading && isHttpClientReady) {
            setFetchApplicationsTrigger(true)
        }
    }, [isHttpClientReady]);
    useEffect(() => {
        if (fetchApplicationsTrigger && !applicationsIsLoading) {
            Application.getApplications()
        }
    }, [fetchApplicationsTrigger]);
    useEffect(() => {
        console.log("applications", applications)
    }, [applications]);
    const handleNewApplicationClick = () => {
        if (applicationIsInProgress) {
            Notify(t('You cannot create a new application while another application is in progress'), 'error', 4000)
        } else {
            navigate('/new-application')
        }
    }
    const handleCancelApplicationClick = (applicationId: string) => {
        const application = applications.find(app => app.id === applicationId)
        if (application?.cancellable) {
            application.cancel()
        }
    }
    const handleAttendAppointmentClick = (applicationId: string) => {
        const application = applications.find(app => app.id === applicationId)
        if (application?.isAppointmentDueNow) {
            navigate('/Appointment/' + application.id)
        }
    }
    return (
        <div className={css(styles.pageContainer)}>
            <div className={css(styles.container)}>
                <div className={css(styles.applicationsContainer)}>
                    <Container className="d-flex justify-content-between align-items-center flex-column flex-sm-row">
                        <h2>{t('Applications')}</h2>
                        <OverlayTrigger
                            trigger={!applicationIsInProgress ? [] : ['hover', 'focus']}
                            placement="right"
                            delay={{show: 250, hide: 400}}
                            overlay={(
                                <Popover id="popover-basic" show={!applicationIsInProgress} style={{position: "fixed"}}>
                                    <Popover.Header as="h3">{t('Warning') + '!'}</Popover.Header>
                                    <Popover.Body>
                                        {t('You cannot create a new application while another application is in progress')}
                                    </Popover.Body>
                                </Popover>
                            )}
                        >
                            <div className={css(styles.newApplicationContainer)}>
                                <Button className={css(styles.newApplicationButton)} variant="outline-primary"
                                        size={"lg"}
                                        disabled={applicationIsInProgress} onClick={handleNewApplicationClick}>
                                    {t('New Application')}
                                </Button>
                            </div>
                        </OverlayTrigger>

                    </Container>
                    <Container className={css(styles.border)}/>
                    <Container className={css(styles.applicationListContainer)}>
                        {applications.length ?
                            applications.map((application, index) =>
                                <ApplicationItem key={index} application={application}
                                                 onClick={handleAttendAppointmentClick}
                                                 onCancel={handleCancelApplicationClick}/>
                            ) : (
                                <div>You don't have any applications</div>
                            )}
                    </Container>
                </div>
            </div>
        </div>
    )
}
export {HomePage}
