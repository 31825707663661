import {StyleSheet} from "aphrodite";
import theme from '../themes/themeConstants.ts'

const styles = StyleSheet.create({
    pageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        // flexGrow: 1,
        padding: 10,
    },
    parentContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        textAlign: 'left',
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 20,
        paddingBottom: 20,
        width: 600,
        borderStyle: 'solid',
        borderWidth: 2,
        borderColor: theme.colors.primary,
        borderRadius: 5,
    },
    radio: {},
    input: {
        paddingTop: 15,
        paddingBottom: 15,
    },
    inputError: {
        color: '#d00707',
    },
    loginButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 10,
        backgroundColor: theme.colors.primary,
        borderColor: theme.colors.primary,
        ':hover': {
            backgroundColor: theme.colors.secondary,
            borderColor: theme.colors.secondary,
        }
    },
    buttonLabel: {
        padding: 5,
    },
    spinnerContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        padding: 10,
    },
    optionItem: {},
    timeslotText: {
        fontWeight: 'bold',
    },
    inputGroup: {
        width: '100%',
        justifyContent: 'space-between',
    },
    inputGroupSpacer: {
        width: 10
    }
});
export default styles;
