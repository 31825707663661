import {StyleSheet} from 'aphrodite';
import theme from '../../../../themes/themeConstants.ts';

export default StyleSheet.create({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        whiteSpace: 'nowrap',
    },
    text: {
        fontFamily: "Roboto, Arial, sans-serif",
        fontSize: 18,
        color: theme.colors.white
    },
    flag: {
        paddingLeft: 10,
        // paddingRight: 5,
        height: 20,
        width: 'auto',
        cursor: 'pointer',
    }
});
