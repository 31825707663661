import {
    RequestParams,
    RequestEndpoint,
    RequestMethod,
    BasUrl,
    RefreshTokenFunction, StringParamsObject, HttpClientInitParams, RequestHeaders, JwtParsedToken
} from "../Types/RequestTypes.ts";
import {isJSON, isObject, parseJwt} from "./Helpers.ts";
import Logger, {StyleType} from "./Logger.ts";
import User from "../Models/User/User.ts";

class HttpClient {
    private readonly _baseUrl: BasUrl;
    private readonly _apiVersion: string | undefined;
    private _token: string | undefined;
    private _parsedToken: JwtParsedToken | undefined;
    private _tokenExpiresAt: Date | undefined;
    private _refreshToken: string | undefined;
    private _defaultHeaders: {
        [key: string]: string;
    } | undefined;
    private _refreshTokenFunction: RefreshTokenFunction | undefined;
    private readonly _requestLoggingEnabled: boolean
    private static instance: HttpClient;
    private readonly _unauthenticatedEndpointsWithoutVersion = ['/auth/token', '/auth/token/refresh', '/auth/register']

    constructor(initParams: HttpClientInitParams) {
        this._baseUrl = initParams.baseUrl;
        this._apiVersion = initParams.apiVersion;
        this._refreshTokenFunction = initParams.refreshTokenFunction;
        this._defaultHeaders = initParams.defaultHeaders || {};
        this._requestLoggingEnabled = import.meta.env.VITE_HTTP_REQUEST_LOGGIN_ENABLED === 'true';
        HttpClient.instance = this
    }

    private async refreshToken(): Promise<void> {
        try {
            if (this._token && this._refreshToken && this._refreshTokenFunction) {
                const tokenResponse = await this._refreshTokenFunction(this._token, this._refreshToken);
                if (tokenResponse?.data.access_Token && tokenResponse?.data.refresh_Token) {
                    this._token = tokenResponse.data.access_Token;
                    this._refreshToken = tokenResponse.data.refresh_Token;
                    this._parsedToken = parseJwt(this._token)
                    this._tokenExpiresAt = new Date(Date.now() + tokenResponse.data.expires_In * 1000)
                    // if (this._parsedToken?.exp) {
                    //     this._tokenExpiresAt = new Date(this._parsedToken.exp * 1000)
                    // }

                    User.setTokens(tokenResponse.data)
                }
            }
        } catch (e) {
            Logger.error('HttpClient@refreshToken Exception:', e)
        }
    }

    private async _setupAuthentication(endpoint: RequestEndpoint): Promise<void> {
        if (!this._unauthenticatedEndpointsWithoutVersion.includes(endpoint.toLowerCase()) && this._isTokenExpired()) {
            await this.refreshToken();
        }
        if (this._defaultHeaders) {
            this._defaultHeaders.Authorization = `Bearer ${this._token}`;
        } else {
            this._defaultHeaders = {Authorization: `Bearer ${this._token}`};
        }
    }

    private _isTokenExpired(): boolean {
        if (this._token && this._tokenExpiresAt) {
            // Logger.console('HttpClient@_isTokenExpired',
            //     'Token expires at:', this._tokenExpiresAt,
            //     'Current time:', new Date())
            return new Date().getTime() > this._tokenExpiresAt.getTime() - 60000 // 60000ms (1 minute) safety margin
        }
        return false
    }

    setTokens(accessToken: string, refreshToken?: string): void {
        this._token = accessToken;
        this._parsedToken = parseJwt(accessToken)
        if (this._parsedToken?.exp) {
            this._tokenExpiresAt = new Date(this._parsedToken.exp * 1000)
        }

        if (refreshToken) {
            this._refreshToken = refreshToken;
        }
    }

    setRefreshTokenFunction(refreshTokenFunction: RefreshTokenFunction): void {
        this._refreshTokenFunction = refreshTokenFunction;
    }

    private _constructUrl(endpoint: RequestEndpoint): string {

        return `${this._baseUrl}` +
            `${this._apiVersion && !this._unauthenticatedEndpointsWithoutVersion.includes(endpoint) ? '/' + this._apiVersion : ''}` +
            `${endpoint}`
    }

    private _objectToQueryString(obj: StringParamsObject): string {
        try {
            const keys = Object.keys(obj || {});
            if (keys.length > 0) {
                return '?' + keys
                    .map((key) => key + '=' + obj[key])
                    .join('&');
            }
            return '';
        } catch (e) {
            return '';
        }
    }

    private async _handleBlobResponse(response: Response): Promise<Blob> {
        if (!response.ok) {
            // if (response.status === 401) {
            // } else if (response.status === 500) {
            // }
            return this._generateErrorResponse(response);
        }
        return await response.blob();
    }

    private async _handleResponse<T>(response: Response, logger?: Logger): Promise<T> {
        if (!response.ok) {
            if (response.status === 401) {
                await User.logout()
                // auto logout if 401 response returned from api
                // global.window.location.href = window.location.hostname;
                // global.window.location.href = "";
            } else if (response.status === 500) {
                // clear cookie;
                // global.window.location.href = window.location.hostname;
            }
            return this._generateErrorResponse(response, logger);
        }
        const responseString = await response.text();
        if (isJSON(responseString)) {
            const result = JSON.parse(responseString) as T
            if (logger) {
                this._log(logger, 'success', 'REQUEST SUCCESS: ' + response.status, result)
            }
            return result
        }
        return responseString as T
    }

    private async _generateErrorResponse(response: Response, logger?: Logger) {
        const responseString = await response.text();
        if (logger) {
            this._log(logger, 'error', responseString)
        }
        if (isJSON(responseString)) {
            const error = JSON.parse(responseString)
            let message = ''
            if (isObject(error)) {
                if (error.message) {
                    message = error.message
                } else {
                    Object.keys(error).forEach(key => {
                        message = message += key + ': ' + error[key] + '. '
                    })
                }
            } else if (Array.isArray(error)) {
                for (let i = 0; i < error.length; i++) {
                    message = message += error[i] + '. '
                }
            } else {
                message = responseString
            }

            return Promise.reject({message: message});
        }
        return Promise.reject({message: responseString})
    }

    private _getAuthHeader(): RequestHeaders {
        if (this._token) {
            return {
                'Authorization': 'Bearer ' + this._token,
            }
        }
        return {}
    }

    private _log(logger: Logger, level: StyleType, ...args: unknown[]): void {
        if (this._requestLoggingEnabled) {
            switch (level) {
                case "base":
                    logger.info(...args)
                    break
                case "request":
                    logger.request(...args)
                    break
                case "response":
                    logger.response(...args)
                    break
                case "success":
                    logger.success(...args)
                    break
                case "warning":
                    logger.warning(...args)
                    break
                case "error":
                    logger.error(...args)
                    break
                case "mag":
                    logger.mag(...args)
                    break
                default:
                    break
            }
        }
    }

    private async request<T>(endpoint: RequestEndpoint, params: RequestParams, method: RequestMethod): Promise<T> {
        const started = new Date();
        const logger = new Logger({logUuid: true})
        try {
            let url = this._constructUrl(endpoint);
            await this._setupAuthentication(endpoint)
            const options: RequestInit = {
                method: method,
                headers: {
                    ...(params.headers || {}),
                    ...(this._unauthenticatedEndpointsWithoutVersion.includes(endpoint) ? {"Content-Type": "application/json"} : {
                        ...this._defaultHeaders,
                        ...this._getAuthHeader()
                    }),
                },
                body: method !== 'GET' ? params.body as BodyInit || undefined : undefined
            }
            if (params.body && isObject(params.body)) {
                if (method === 'GET') {
                    url = url + this._objectToQueryString(params.body as StringParamsObject)
                } else {
                    options.body = JSON.stringify(params.body)
                }
            }

            this._log(logger, 'request', `${method}: ${url}`, 'HEADERS:', options.headers, 'BODY:', params.body)
            const response = await fetch(url, options);
            return this._handleResponse<T>(response, logger);
        } catch (e) {
            if (e instanceof Error) {
                this._log(logger, 'error', e.message);
                return Promise.reject(e.message)
            } else {
                this._log(logger, 'error', e);
                return Promise.reject('Request failed.');
            }
        } finally {
            const ended = new Date();
            this._log(logger, 'warning', 'Request completed in ' + ((ended.getTime() - started.getTime()) / 1000).toFixed(3) + 's')
        }
    }

    private async blobRequest(endpoint: RequestEndpoint, params: RequestParams, method: RequestMethod): Promise<Blob> {
        let url = this._constructUrl(endpoint);
        const options: RequestInit = {
            headers: {
                ...this._defaultHeaders,
                ...(params.headers || {})
            },
            body: params.body as BodyInit || undefined
        }
        if (params.body && isObject(params.body)) {
            if (method === 'GET') {
                url = url + this._objectToQueryString(params.body as StringParamsObject)
            } else {
                options.body = JSON.stringify(params.body)
            }
        }
        const response = await fetch(url, options);
        return this._handleBlobResponse(response);
    }

    async get<T>(endpoint: RequestEndpoint, params: RequestParams): Promise<T> {
        if (!params) {
            params = {body: null}
        }
        return this.request<T>(endpoint, params, 'GET')
    }

    async post<T>(endpoint: RequestEndpoint, params: RequestParams): Promise<T> {
        if (!params) {
            params = {body: null}
        } else if (!params.body) {
            params.body = null
        }
        return this.request<T>(endpoint, params, 'POST')
    }

    async patch<T>(endpoint: RequestEndpoint, params?: RequestParams): Promise<T> {
        if (!params) {
            params = {body: null}
        } else if (!params.body) {
            params.body = null
        }
        return this.request<T>(endpoint, params, 'PATCH')
    }

    async delete<T>(endpoint: RequestEndpoint, params?: RequestParams): Promise<T> {
        if (!params) {
            params = {body: null}
        } else if (!params.body) {
            params.body = null
        }
        return this.request<T>(endpoint, params, 'DELETE')
    }

    async getBlob(endpoint: RequestEndpoint, params: RequestParams): Promise<Blob> {
        return this.blobRequest(endpoint, params, 'GET')
    }

    public static getInstance(initParams?: HttpClientInitParams): HttpClient | null {
        if (!HttpClient.instance) {
            if (initParams) {
                Logger.warn('HttpClient created new instance')
                HttpClient.instance = new HttpClient(initParams);
            } else {
                return null
            }
        }
        return HttpClient.instance;
    }

    // printElapsed = () => {
    //     if (this.started && this.ended) {
    //         if (isWebApp) {
    //             let style = styles.base.join(';') + ';';
    //             style += styles.warning.join(';');
    //             console.info(
    //                 `%cCompleted in: ${this.elapsed()} seconds`,
    //                 style
    //             );
    //         } else {
    //             console.info(
    //                 colors.KYEL +
    //                 'Completed in: ' +
    //                 this.elapsed() +
    //                 ' seconds' +
    //                 colors.KNRM,
    //             );
    //         }
    //     }
    // };
    //
    // elapsed = () => {
    //     if (this.started && this.ended) {
    //         return ((this.ended.getTime() - this.started.getTime()) / 1000).toFixed(
    //             3,
    //         );
    //     }
    // };
}

export default HttpClient
