import {Action, combineSlices, configureStore, ThunkAction} from '@reduxjs/toolkit'
import {setupListeners} from "@reduxjs/toolkit/query";
import {userSlice} from '../Models/User/userSlice.ts'
import {applicationsSlice} from "../Models/Application/applicationsSlice.ts";
import { navigatorSlice } from '../Models/Navigator/navigatorSlice.ts';

// `combineSlices` automatically combines the reducers using
// their `reducerPath`s, therefore we no longer need to call `combineReducers`.
const rootReducer = combineSlices(userSlice, applicationsSlice, navigatorSlice);

// Infer the `RootState` type from the root reducer
export type RootState = ReturnType<typeof rootReducer>

// The store setup is wrapped in `makeStore` to allow reuse
// when setting up tests that need the same store config
export const makeStore = (preloadedState?: Partial<RootState>) => {
    const store = configureStore({
        reducer: rootReducer,
        // Adding the api middleware enables caching, invalidation, polling,
        // and other useful features of `rtk-query`.
        preloadedState,
    })
    // configure listeners using the provided defaults
    // optional, but required for `refetchOnFocus`/`refetchOnReconnect` behaviors
    setupListeners(store.dispatch)
    return store
}

export const store = makeStore()

// Infer the type of `store`
export type AppStore = typeof store
// Infer the `AppDispatch` type from the store itself
// export type AppDispatch = typeof store.dispatch
// Infer the `AppDispatch` type from the store itself
export type AppDispatch = AppStore["dispatch"]
export type AppThunk<ThunkReturnType = void> = ThunkAction<
    ThunkReturnType,
    RootState,
    unknown,
    Action
>

// Export a hook that can be reused to resolve types
// export const useAppDispatch = useDispatch.withTypes<AppDispatch>()
// export default store
