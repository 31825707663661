import * as enTranslations from './en/translations.json'
import * as elTranslations from './el/translations.json'


const resources = {
    en: {
        translations: enTranslations,
    },
    el: {
        translations: elTranslations,
    },
};

export default resources;
