import { createAppSlice } from "../../State/createAppSlice.ts";
import { NavigatorSliceState } from "../../Types/NavigatorTypes";
import { PayloadAction } from "@reduxjs/toolkit";

const initialState: NavigatorSliceState = {
    applicationId: undefined,
};

export const navigatorSlice = createAppSlice({
    name: "navigator",
    initialState,
    reducers: (create) => ({
        setApplicationId: create.reducer(
            (state, action: PayloadAction<string | undefined>) => {
                console.log("navigatorSlice@setApplicationId", action.payload);
                state.applicationId = action.payload;
            }
        ),
    }),
    selectors: {},
});

export const { setApplicationId } = navigatorSlice.actions;
export const {} = navigatorSlice.selectors;
