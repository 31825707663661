import {useEffect, useState} from "react";
import {Card, Container} from "react-bootstrap";
import QRCode from "react-qr-code";
import {useLocation, useNavigate} from "react-router-dom";
import useTranslate from "../../Hooks/useTranslate";
import {css} from "aphrodite";
import styles from "./styles";
import Navigator from "../../Models/Navigator/Navigator";


const AppointmentLandingPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    const location = useLocation();
    const [isMobile, setIsMobile] = useState<boolean | null>(null);
    const currentUrl = window.location.href;


    useEffect(() => {
        const checkMobileDevice = () => {
            const userAgent = navigator.userAgent;

            if (/android|iPad|iPhone|iPod|kindle/i.test(userAgent)) {
                setIsMobile(true);
                Navigator.setApplicationId(location.pathname.split("/")[2])
                navigate("/video-lobby");
            } else {
                setIsMobile(false);
            }
        };

        checkMobileDevice();
    }, []);

    return (
        <Container className="d-flex justify-content-center align-items-center m-auto">
            {
                isMobile === false &&
                <Card className={css(styles.card)}>
                    <Card.Body>
                        <Card.Title>{t("Scan this QR from a mobile device")}</Card.Title>
                        <QRCode value={currentUrl} size={200}/>
                    </Card.Body>
                </Card>
            }
        </Container>
    );
};


export default AppointmentLandingPage;
