import {css} from "aphrodite";
import styles from "./styles.tsx";
import Application from "../../../../Models/Application/Application.ts";
import {ApplicationStatusNames} from "../../../../Utils/Enumerations.ts";
import {Button, Col, Container, Row} from "react-bootstrap";
import {useAppSelector} from "../../../../State/hooks.ts";

interface ApplicationItemProps {
    application: Application;
    onClick?: (applicationId: string) => void;
    onCancel?: (applicationId: string) => void;
}

const ApplicationItem = (props: ApplicationItemProps) => {
    const {application} = props
    const applicationsIsLoading = useAppSelector(Application.selectApplicationsIsLoading);
    const handleOnClick = () => {
        props.onClick && props.onClick(application.id)
    }

    const handleCancelApplication = () => {
        props.onCancel && props.onCancel(application.id)
    }

    return (
        <Container
            className={css(styles.applicationContainer)}
        >
            <div className={css(styles.application)}>
                <Container>
                    <Row>
                        <Col>
                            <Button
                                className={css(styles.titleButton)}
                                variant="link"
                                color="primary"
                                disabled={!application.isAppointmentDueNow}
                                onClick={handleOnClick}
                            >
                                <h5>{" Application:  " + application.created}</h5>
                            </Button>
                        </Col>
                        <Col>
                            <div>Start: {application.appointmentStart}</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <h6>
                                {"Status: " + ApplicationStatusNames[application.status]}
                            </h6>
                        </Col>
                        <Col>
                            <div>End: {application.appointmentEnd}</div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Button
                disabled={!application.cancellable || applicationsIsLoading}
                variant="outline-danger text-nowrap"
                onClick={handleCancelApplication}
            >
                Cancel
            </Button>
        </Container>
    );
}
export {ApplicationItem}
