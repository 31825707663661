import Logger from "./Logger.ts";

const isBlank = (str: string) => {
    return /^\s*$/.test(str);
};
const isJSON = (str: string) => {
    if (isBlank(str)) {
        return false;
    }
    str = str.replace(/\\(?:["\\\/bfnrt]|u[0-9a-fA-F]{4})/g, '@');
    str = str.replace(
        /"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g,
        ']',
    );
    str = str.replace(/(?:^|:|,)(?:\s*\[)+/g, '');
    return /^[\],:{}\s]*$/.test(str);
};
const isObject = (objValue: unknown) => {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object;
}
const parseJwt = (token: string) => {
    try {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );

        return JSON.parse(jsonPayload);
    } catch (e) {
        Logger.console("parseJwt Exception", e)
        return null
    }
};

interface SplitOnFirstWordType {
    firstWord: string;
    restOfString: string;
}

const splitOnFirstWord = (inputString: string): SplitOnFirstWordType => {
    try {
        const match = inputString.match(/\b\w+\b/);
        if (match) {
            const firstWord = match[0];
            const restOfString = inputString.slice(inputString.indexOf(firstWord) + firstWord.length).trim();
            return {
                firstWord: firstWord,
                restOfString: restOfString
            };
        }
        return {
            firstWord: '',
            restOfString: inputString
        };
    } catch (e) {
        if (e instanceof Error) {
            Logger.error("splitOnFirstWord Error", e)
        }
        return {
            firstWord: '',
            restOfString: ''
        };
    }
}
export {
    isBlank,
    isJSON,
    isObject,
    parseJwt,
    splitOnFirstWord,
}
