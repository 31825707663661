import {RootState, store} from "../../State/store.ts";
import { createSelector } from "@reduxjs/toolkit";
import { setApplicationId } from "./navigatorSlice.ts";

class Navigator {

    /** ACTIONS **/
    static setApplicationId = async (id?: string) => {
        return store.dispatch(setApplicationId(id));
    };

    /** SELECTORS **/
    static redirectToVideoLobby = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return !!navigatorState.applicationId;
    })
    static selectApplicationId = createSelector([(state: RootState) => state.navigator], (navigatorState) => {
        return navigatorState.applicationId;
    })
}

export default Navigator;
