const colors = {
    primary: '#003476',
    secondary: '#0066cc',
    success: '#5cb85c',
    info: '#5bc0de',
    warning: '#f0ad4e',
    danger: '#d9534f',
    dark: '#343a40',
    grey: '#6c757d',
    mediumGrey: '#868e96',
    lightMediumGrey: '#999',
    lightGrey: '#ced4da',
    offWhite: '#f5f5f5',
    light: '#f8f9fa',
    white: '#fff',
    black: '#000',
};
export default {
    colors
}
