import {StyleSheet} from "aphrodite";
import theme from '../themes/themeConstants.ts'

const styles = StyleSheet.create({
    pageContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        // flexGrow: 1,
        height: 'calc(100% - 80px)',

        padding: 10,
    },
    container: {
        width: 600,
        borderStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.colors.primary,
    },
});
export default styles;
