import {StyleSheet} from "aphrodite";
import theme from '../themes/themeConstants.ts'

const styles = StyleSheet.create({
    container: {
        padding: 20,
    },
    card: {
        textAlign: 'left',
        padding: 20,
        maxWidth: 500,
        width: '100%',
        minWidth: 200,
        margin: 'auto',
    },
    input: {
        paddingTop: 15,
        paddingBottom: 15,
    },
    inputError: {
        color: '#d00707',
    },
    loginButton: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        padding: 10,
        backgroundColor: theme.colors.primary,
        borderColor: theme.colors.primary,
        ':hover': {
            backgroundColor: theme.colors.secondary,
            borderColor: theme.colors.secondary,
        }
    },
    buttonLabel: {
        padding: 5,
    },
    linkContainer: {
        width: '100%',
        textAlign: 'center',
    },
});
export default styles;
