import {UserType, UserCredentials, UserSliceState, TokenResponse, ApiJsonResponse} from "../../Types";
import {createAppSlice} from "../../State/createAppSlice.ts";
import {authenticateUser, registerUser} from "./userAPI.ts";
import Logger from "../../Utils/Logger.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {isJSON} from "../../Utils/Helpers.ts";
import { UserRegistrationDetails, UserRegistrationResponse} from "../../Types/UserTypes.ts";

const storedUser = sessionStorage.getItem('user')
const data: UserType | undefined = storedUser !== null && isJSON(storedUser) ? JSON.parse(storedUser) : undefined

export const initialState: UserSliceState = {
    isLoading: false,
    isAuthenticated: !!data,
    error: '',
    httpClientReady: false,
    data: data,
}


export const userSlice = createAppSlice({
    name: 'user',
    initialState,
    reducers: (create) => ({
        save: create.reducer((state, action: PayloadAction<UserType>) => {
            state.data = action.payload;
        }),
        reset: create.reducer((state) => {
            sessionStorage.removeItem('user')
            state.isLoading = false;
            state.isAuthenticated = false;
            state.error = '';
            state.data = undefined
        }),
        setTokens: create.reducer((state, action: PayloadAction<TokenResponse>) => {
            if (state.data) {
                state.data.access_Token = action.payload.access_Token
                state.data.refresh_Token = action.payload.refresh_Token
                // state.data.tokenExpiresAt = Date.now() + (action.payload.expires_In * 1000)
                sessionStorage.setItem('user', JSON.stringify(state.data))
            }
        }),
        setHttpClientReady: create.reducer((state, action: PayloadAction<boolean>) => {
            state.httpClientReady = action.payload
        }),
        authenticate: create.asyncThunk(
            async (credentials: UserCredentials): Promise<UserType> => {
                const response: ApiJsonResponse<UserType> = await authenticateUser(credentials);
                // The value we return becomes the `fulfilled` action payload
                return {
                    ...response.data,
                }
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state, action) => {
                    state.data = {
                        ...state.data,
                        ...action.payload
                    }
                    sessionStorage.setItem('user', JSON.stringify(state.data))
                    state.isAuthenticated = true
                    state.error = ''
                },
                rejected: (state, action) => {
                    Logger.console("rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                    state.isAuthenticated = false
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
        register: create.asyncThunk(
            async (registrationDetails: UserRegistrationDetails): Promise<ApiJsonResponse<UserRegistrationResponse>> => {
                const response = await registerUser(registrationDetails)
                // The value we return becomes the `fulfilled` action payload
                return response
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state, action) => {
                    Logger.console("userSlice@register fulfilled action.payload", action.payload)
                    state.error = ''
                },
                rejected: (state, action) => {
                    Logger.console("userSlice@register rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
    }),
    selectors: {
        selectUser: userState => userState.data,
        selectUserError: userState => userState.error,
        selectUserIsLoading: userState => userState.isLoading,
        selectIsAuthenticated: userState => userState.isAuthenticated,
    }
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
    authenticate,
    register,
    save,
    reset,
    setTokens,
    setHttpClientReady
} = userSlice.actions
export const {
    selectUser,
    selectUserError,
    selectUserIsLoading,
    selectIsAuthenticated
} = userSlice.selectors
// Export the slice reducer as the default export
// export default userSlice.reducer
