import {TokenResponse, UserCredentials, UserType} from "../../Types";
import {authenticate, register, userSlice, reset as resetUser, setTokens, setHttpClientReady} from "./userSlice.ts";
import {reset as resetApplications} from "../Application/applicationsSlice.ts";
import {RootState, store} from "../../State/store.ts";
import {createSelector} from "@reduxjs/toolkit";
import {BaseModel} from "../../Types/ModelTypes.ts";
import {UserRegistrationDetails} from "../../Types/UserTypes.ts";

class User implements BaseModel {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    access_Token: string;
    refresh_Token: string;
    expires_In: number;


    constructor(data: UserType) {
        this.firstName = data.firstName;
        this.lastName = data.lastName;
        this.phoneNumber = data.phoneNumber;
        this.email = data.email;
        this.access_Token = data.access_Token;
        this.refresh_Token = data.refresh_Token;
        this.expires_In = data.expires_In;
    }

    save = () => {
        if (userSlice?.actions?.save && typeof userSlice.actions.save === 'function') {
            store.dispatch(userSlice.actions.save(JSON.parse(JSON.stringify(this))))
        } else {
            throw new Error(
                'Invalid save parameters. Slice parameter must include a save action',
            );
        }
    }

    /** ACTIONS **/
    static authenticate = async (credentials: UserCredentials) => {
        const result = await store.dispatch(authenticate(credentials));
        if (result.meta.requestStatus === 'rejected') {
            // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
            if (result?.error?.message) {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                return Promise.reject(result.error.message)
            }
            return Promise.reject("User Authentication failed")
        }
        return result.payload
    }
    static register = async (registrationDetails: UserRegistrationDetails) => {
        const result = await store.dispatch(register(registrationDetails));
        if (result.meta.requestStatus === 'rejected') {
            // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
            if (result?.error?.message) {
                // @ts-expect-error When the async thunk action is rejected, it returns an object with an error object.
                return Promise.reject(result.error.message)
            }
            return Promise.reject("User Registration failed")
        }
        return result.payload
    }
    static logout = async () => {
        store.dispatch(resetApplications());
        store.dispatch(resetUser());
    }

    static setTokens = (tokenResponse: TokenResponse) => {
        const result = store.dispatch(setTokens(tokenResponse));
        return result.payload
    }
    static setHttpClientIsReady = (ready: boolean) => {
        const result = store.dispatch(setHttpClientReady(ready));
        return result.payload
    }

    /** SELECTORS **/
    static selectUser = createSelector([(state: RootState) => state.user], (userState) => {
        return userState.data
    })
    static selectIsAuthenticated = createSelector([(state: RootState) => state.user], (userState) => {
        return !!userState.data?.access_Token
    })
    static selectHttpClientIsReady = createSelector([(state: RootState) => state.user], (userState) => {
        return !!userState?.httpClientReady
    })

}

export default User;
