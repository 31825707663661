import { StyleSheet } from 'aphrodite';

export default StyleSheet.create({
    image: {
        height: '100%',
        ':hover': {
            cursor: "pointer"
        }
    },
    logoContainer: {
        height: '100%',
    },
});
