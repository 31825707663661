import {toast, ToastOptions} from 'react-toastify';

type NotificationType = 'success' | 'error' | 'warn' | 'info' | 'dark';

const Notify = (message: string, type?: NotificationType, autoClose?: number | false): void => {

    const options: ToastOptions = {
        // position: 'bottom-left',
        autoClose: autoClose || false,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    };

    switch (type) {
        case 'success':
            console.log("success")
            toast.success(message, options);
            break;
        case 'error':
            console.log("error")
            toast.error(message, options);
            break;
        case 'warn':
            console.log("warn")
            toast.warn(message, options);
            break;
        case 'info':
            console.log("info")
            toast.info(message, options);
            break;
        case 'dark':
            console.log("dark")
            toast.dark(message, options);
            break;
        default:
            console.log("default")
            toast(message, options);  // fallback to default toast
            break;
    }
};

export {Notify};
