import {useAppSelector} from "../../State/hooks";
import {
    selectUserIsLoading
} from "../../Models/User/userSlice.ts";
import React, {useEffect, useState} from "react";
import User from "../../Models/User/User.ts";
import useTranslate from "../../Hooks/useTranslate.ts";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './styles.tsx'
import {css} from "aphrodite";
import {Card, Container, Spinner} from "react-bootstrap";
import Logger from "../../Utils/Logger.ts";
import {useNavigate} from "react-router-dom";
import {Notify} from "../../Utils/Notify.ts";
import Navigator from "../../Models/Navigator/Navigator.ts";

const SignInPage = () => {
    const [username, setUsername] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const userIsLoading = useAppSelector(selectUserIsLoading)
    const redirectToVideoLobby = useAppSelector(Navigator.redirectToVideoLobby);
    const {t} = useTranslate('translations');
    const navigate = useNavigate();
    useEffect(() => {
        // something
    }, []);
    const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUsername(event.target.value);
    }
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }

    const login = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            if (form.checkValidity()) {
                if (username && password) {
                    await User.authenticate({
                        email: username,
                        password: password
                    });
                    (redirectToVideoLobby) && navigate('/video-lobby');
                }
                setValidated(true);
            } else {
                setValidated(false);
            }
        } catch (e) {
            if (e instanceof Error) {
                Logger.error("SignInPage@login() Exception: " + e.message)
                Notify(t(e.message), "error", 5000)
            } else if (typeof e === 'string') {
                Logger.error("SignInPage@login() Exception: ", e)
                Notify(t(e), "error", 5000)
            }
        }
    }

    return (
        <Container className={css(styles.container)}>
            <Card className={css(styles.card)}>
                <Form noValidate={true} validated={validated} onSubmit={login}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label column={"lg"}>{t('Email')}</Form.Label>
                        <Form.Control type="email" className={css(styles.input)}
                                    placeholder={t("Enter your email")}
                                    value={username || ''} readOnly={userIsLoading} required
                                    aria-describedby="emailHelpBlock"
                                    onChange={handleUsernameChange}/>
                        {username === '' && (
                            <Form.Text className={css(styles.inputError)} id="emailHelpBlock" muted>
                                {t('Please enter your email')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="formBasicPassword">
                        <Form.Label column={"lg"}>{t('Password')}</Form.Label>
                        <Form.Control type="password" className={css(styles.input)} placeholder={t("Enter your password")}
                                    value={password || ''} readOnly={userIsLoading} required
                                    onChange={handlePasswordChange}/>
                        {password === '' && (
                            <Form.Text className={css(styles.inputError)} id="passwordHelpBlock" muted>
                                {t('Please enter your password')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex justify-content-center" controlId="formBasicCheckbox">
                        <Button type={"submit"} className={css(styles.loginButton)}
                                disabled={userIsLoading}>
                            {userIsLoading ? <Spinner
                                as="span"
                                animation="border"
                                // size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : <div className={css(styles.buttonLabel)}>{t('Login')}</div>}
                        </Button>
                    </Form.Group>
                </Form>
                <div className={css(styles.linkContainer)}>
                    <Button onClick={() => navigate('/register')} variant={"link"}>{t("Register")}</Button>
                </div>
            </Card>
        </Container>
    )
}
export {SignInPage}
