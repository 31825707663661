import {Modal} from "react-bootstrap";
import Button from "react-bootstrap/Button";
import {ReactNode} from "react";
import useTranslate from "../../../Hooks/useTranslate.ts";

interface CustomModalProps {
    title: string;
    show: boolean;
    onHide?: () => void;
    onCancel?: () => void;
    cancelText?: string;
    onConfirm?: () => void;
    confirmText?: string;
    children?: ReactNode;
    backdrop?: boolean | 'static';
}

const CustomModal = (props: CustomModalProps) => {
    const {show, title, children, onConfirm, onCancel, onHide, cancelText, confirmText, backdrop} = props;
    const {t} = useTranslate('translations');
    const handleConfirm = () => {
        if (onConfirm && typeof onConfirm === 'function') {
            onConfirm()
        }
    }
    const handleCancel = () => {
        if (onCancel && typeof onCancel === 'function') {
            onCancel()
        }
    }
    return (
        <Modal
            onHide={onHide}
            show={show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop={backdrop || (onHide ? true : 'static')}
            centered
        >
            <Modal.Header closeButton={!!onHide}>
                <Modal.Title id="contained-modal-title-vcenter">
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                {onCancel && <Button variant={"secondary"} onClick={handleCancel}>{cancelText || t('Close')}</Button>}
                {onConfirm && <Button onClick={handleConfirm}>{confirmText || t('Ok')}</Button>}
            </Modal.Footer>
        </Modal>
    );
}
export {CustomModal}
export type {CustomModalProps}
