import {StyleSheet} from "aphrodite";
import theme from '../../../themes/themeConstants.ts'

const styles = StyleSheet.create({
    applicationContainer: {
        marginBottom: 10,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: "1px solid",
        borderColor: theme.colors.grey,
    },
    application: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        color: theme.colors.black,
    },
    titleButton: {
        padding: 0,
        textDecoration: 'none',
    }
});
export default styles;
