import './App.css'
import HttpClient from "./Utils/HttpClient.ts";
import {BasUrl} from "./Types/RequestTypes.ts";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import {useAppSelector} from "./State/hooks.ts";
import {SignInPage} from "./View/SignInPage/SignInPage.tsx";
import {HomePage} from "./View/HomePage/HomePage.tsx";
import User from "./Models/User/User.ts";
import {SignUpPage} from "./View/SignUpPage/SignUpPage.tsx";
import {refreshAccessToken} from "./Models/User/userAPI.ts";
import {NavBar} from "./View/components/NavBar";
import {NewApplicationPage} from "./View/NewApplicationPage/NewApplicationPage.tsx";
import 'react-toastify/dist/ReactToastify.css';
import {AccountActivationPage} from "./View/AccountActivationPage/AccountActivationPage.tsx";
import AppointmentLandingPage from './View/AppointmentLandingPage/AppointmentLandingPage.tsx';
import VideoLobbyPage from './View/VideoLobbyPage/VideoLobbyPage.tsx';
import VideoRoomPage from './View/VideoRoomPage/VideoRoom.tsx';

// import {useWorker} from "./Utils/useWorker.ts";

function App() {
    const isAuthenticated = useAppSelector(User.selectIsAuthenticated)
    const authUser = useAppSelector(User.selectUser)
    // useWorker()
    useEffect(() => {
        const baseUrl: BasUrl = import.meta.env.VITE_API_HOST_URL;
        const apiVersion = import.meta.env.VITE_API_VERSION;
        let httpClient = HttpClient.getInstance()
        if (!httpClient) {
            httpClient = HttpClient.getInstance({
                baseUrl, apiVersion, defaultHeaders: {
                    'Content-Type': 'application/json'
                }
            })
        }

        if (authUser?.access_Token && httpClient) {
            httpClient.setTokens(authUser.access_Token, authUser.refresh_Token)
            httpClient.setRefreshTokenFunction(refreshAccessToken)
        }
        User.setHttpClientIsReady(true)
    }, [authUser]);

    return (
        <BrowserRouter>
            <NavBar/>
            <Routes>
                <Route path='/Appointment/:uuid' element={<AppointmentLandingPage/>}/>
                {isAuthenticated &&
                    <>
                        <Route path='/' element={<HomePage/>} index/>
                        <Route path='/new-application' element={<NewApplicationPage/>}/>
                        <Route path='/video-lobby' element={<VideoLobbyPage/>}/>
                        <Route path='/video-room' element={<VideoRoomPage/>}/>
                        <Route path='*' element={<Navigate to={"/"}/>}/>
                    </>
                }
                {!isAuthenticated &&
                    <>
                        <Route path='/login' element={<SignInPage/>} index/>
                        <Route path='/register' element={<SignUpPage/>}/>
                        <Route path='/confirm-email/:userId/:token' element={<AccountActivationPage/>}/>
                        <Route path='*' element={<Navigate to={"/login"}/>}/>
                    </>
                }
            </Routes>
        </BrowserRouter>
    )
}

export default App
