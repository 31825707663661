import {css} from 'aphrodite';
import styles from './styles.js';
import {Menu} from './components/Menu/Menu.tsx';
import {Logo} from './components/Logo/Logo.tsx';
import {useAppSelector} from "../../../State/hooks.ts";
import User from "../../../Models/User/User.ts";
import {ToastContainer} from 'react-toastify';

const NavBar = () => {
    const isAuthenticated = useAppSelector(User.selectIsAuthenticated)
    return (
        <div className={css(styles.navbarContainer)}>
            <div className={css(styles.navbar)}>
                <Logo/>
                {isAuthenticated &&
                    <Menu/>
                }
            </div>
            <ToastContainer/>
        </div>
    );
};
export {NavBar};
