import {useAppSelector} from "../../State/hooks";
import {
    selectUserIsLoading
} from "../../Models/User/userSlice.ts";
import React, {useEffect, useState} from "react";
import User from "../../Models/User/User.ts";
import useTranslate from "../../Hooks/useTranslate.ts";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import styles from './styles.tsx'
import {css} from "aphrodite";
import {Card, Container, Spinner} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import Logger from "../../Utils/Logger.ts";
import {Notify} from "../../Utils/Notify.ts";

const SignUpPage = () => {
    const [firstName, setFirstName] = useState<string | null>(null);
    const [lastName, setLastName] = useState<string | null>(null);
    const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null);
    const [password, setPassword] = useState<string | null>(null);
    const [passwordConfirm, setPasswordConfirm] = useState<string | null>(null);
    const [validated, setValidated] = useState<boolean>(false);
    const userIsLoading = useAppSelector(selectUserIsLoading)
    const {t} = useTranslate('translations');
    const navigate = useNavigate();
    useEffect(() => {
        // something
    }, []);
    const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFirstName(event.target.value);
    }
    const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setLastName(event.target.value);
    }
    const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    }
    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }
    const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPassword(event.target.value);
    }
    const handlePasswordConfirmChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPasswordConfirm(event.target.value);
    }

    const register = async (event: React.FormEvent<HTMLFormElement>) => {
        try {
            const form = event.currentTarget;
            event.preventDefault();
            event.stopPropagation();
            if (form.checkValidity() === true) {
                if (firstName && lastName && phoneNumber && email && password && passwordConfirm && password === passwordConfirm) {
                    await User.register({
                        firstName: firstName,
                        lastName: lastName,
                        email: email,
                        phoneNumber: phoneNumber,
                        password: password
                    })
                }
                setValidated(true);
            } else {
                setValidated(false);
            }
        } catch (e) {
            if (e instanceof Error) {
                Logger.error("SignUpPage@register() Exception: " + e.message)
                Notify(t(e.message), "error", 5000)
            } else if (typeof e === 'string') {
                Logger.error("SignUpPage@register() Exception: ", e)
                Notify(t(e), "error", 5000)
            }
        }
    }

    return (
        <Container className={css(styles.container)}>
            <Card className={css(styles.card)}>
                <Form noValidate={true} validated={validated} onSubmit={register}>
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label column={"lg"}>{t('First Name')}</Form.Label>
                        <Form.Control type="text" className={css(styles.input)} placeholder={t("Enter First Name")}
                                    value={firstName || ''} readOnly={userIsLoading} required
                                    onChange={handleFirstNameChange}/>
                        {firstName === '' && (
                            <Form.Text className={css(styles.inputError)} id="firstNameHelpBlock" muted>
                                {t('Please enter your First Name')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label column={"lg"}>{t('Last Name')}</Form.Label>
                        <Form.Control type="text" className={css(styles.input)} placeholder={t("Enter Last Name")}
                                    value={lastName || ''} readOnly={userIsLoading} required
                                    onChange={handleLastNameChange}/>
                        {lastName === '' && (
                            <Form.Text className={css(styles.inputError)} id="lastNameHelpBlock" muted>
                                {t('Please enter your Last Name')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPhoneNumber">
                        <Form.Label column={"lg"}>{t('Mobile Phone Number')}</Form.Label>
                        <Form.Control type="phone" className={css(styles.input)}
                                    placeholder={t("Enter Mobile Phone Number")}
                                    value={phoneNumber || ''} readOnly={userIsLoading} required
                                    onChange={handlePhoneNumberChange}/>
                        {phoneNumber === '' && (
                            <Form.Text className={css(styles.inputError)} id="phoneNumberHelpBlock" muted>
                                {t('Please enter your Mobile Phone Number')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label column={"lg"}>{t('Email')}</Form.Label>
                        <Form.Control type="email" className={css(styles.input)}
                                    placeholder={t("Enter your email")}
                                    value={email || ''} readOnly={userIsLoading} required
                                    aria-describedby="emailHelpBlock"
                                    onChange={handleEmailChange}/>
                        {email === '' && (
                            <Form.Text className={css(styles.inputError)} id="emailHelpBlock" muted>
                                {t('Please enter a valid email')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formPassword">
                        <Form.Label column={"lg"}>{t('Password')}</Form.Label>
                        <Form.Control type="password" className={css(styles.input)} placeholder={t("Enter password")}
                                    value={password || ''} readOnly={userIsLoading} required
                                    onChange={handlePasswordChange} autoComplete={"new-password"}/>
                        {password === '' && (
                            <Form.Text className={css(styles.inputError)} id="passwordHelpBlock" muted>
                                {t('Please enter password')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-5" controlId="formPassword">
                        <Form.Label column={"lg"}>{t('Confirm Password')}</Form.Label>
                        <Form.Control type="password" className={css(styles.input)} placeholder={t("Confirm your password")}
                                    value={passwordConfirm || ''} readOnly={userIsLoading} required
                                    onChange={handlePasswordConfirmChange} autoComplete={"new-password"}/>
                        {(passwordConfirm === '' || (password && passwordConfirm && password !== passwordConfirm)) && (
                            <Form.Text className={css(styles.inputError)} id="passwordConfirmHelpBlock" muted>
                                {t('Passwords do not match')}
                            </Form.Text>
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3 d-flex justify-content-center" controlId="formSubmit">
                        <Button type={"submit"} className={css(styles.loginButton)}
                                disabled={userIsLoading}>
                            {userIsLoading ? <Spinner
                                as="span"
                                animation="border"
                                // size="sm"
                                role="status"
                                aria-hidden="true"
                            /> : <div className={css(styles.buttonLabel)}>{t('Register')}</div>}
                        </Button>
                    </Form.Group>
                </Form>
                <div className={css(styles.linkContainer)}>
                    <Button onClick={() => navigate('/login')} variant={"link"}>{t("Login")}</Button>
                </div>
            </Card>
        </Container>
    )
}
export {SignUpPage}
