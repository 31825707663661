import {StyleSheet} from 'aphrodite';

const styles = StyleSheet.create({
    icon: {
        width: 24,
        height: 24,
    },
    button: {
       color: 'transparent',
    },
});
export default styles;
