import React from 'react';
import {css} from 'aphrodite';


import styles from './styles';
import logo from '../../../Images/YMA.png'
import {useNavigate} from "react-router-dom";

const Logo = () => {
    const navigate = useNavigate()
    return (
        <div className={css(styles.logoContainer)}>
            <img alt={"logo"} src={logo} className={css(styles.image)}
                 onClick={() => navigate("/")}
            />
        </div>
    );
};

const memoizedLogo = React.memo(Logo);
export {memoizedLogo as Logo};
