import {v4 as uuidv4} from 'uuid';
import {StringKeyValueObject} from "../Types";

const enableConsoleLogging = import.meta.env.VITE_CONSOLE_LOGGING_ENABLED === 'true';

const isWebApp = true

const styles = {
    base: [
        "color: #fff",
        // "background-color: #444",
        // "padding: 2px 4px",
        // "border-radius: 2px",
    ],
    request: [
        "font-weight: bold",
        "font-size: 16px",
        "color: #4248f5",
        // "background-color: #181813"
    ],
    response: [
        "font-weight: bold",
        "font-size: 16px",
        "color: #42dcf5",
        // "background-color: #181813"
    ],
    warning: [
        "color: #eef20f",
        // "background-color: #181813"
    ],
    error: [
        "color: #d41209",
        // "background-color: #eee",
        "font-weight: bold",
        "font-size: 16px"
    ],
    mag: [
        "color: #ff00ff",
        // "background-color: #eee",
        "font-weight: bold",
        "font-size: 16px"
    ],
    success: [
        "color: #0bdb18"
    ]
}

/**
 * Work in progress.
 **/
class Logger {
    private readonly logUuid: boolean;
    private readonly printTimestamp: boolean;
    private uuid: string;

    constructor(params: LoggerParams = defaults) {
        this.logUuid = 'logUuid' in params ? params.logUuid as boolean : defaults.logUuid;
        this.printTimestamp =
            'printTimestamp' in params
                ? !!params.printTimestamp
                : defaults.printTimestamp;
        this.uuid = uuidv4();
    }

    static console(...args: unknown[]) {
        if (enableConsoleLogging) {
            console.log(...args)
        }
    }

    static info(...args: unknown[]) {
        if (enableConsoleLogging) {
            console.info(...args)
        }
    }

    static error(...args: unknown[]) {
        if (enableConsoleLogging) {
            console.error(...args)
        }
    }

    static warn(...args: unknown[]) {
        if (enableConsoleLogging) {
            console.warn(...args)
        }
    }

    private printFormatted(style: StyleType, ...args: unknown[]) {
        if (enableConsoleLogging) {
            if (isWebApp) {
                const cssStyle = styles.base.join(';') + ';' + (style !== 'base' ? styles[style].join(';') + ';' : '');
                console.group(`%c${this.ts()}`, cssStyle)
                for (let i = 0; i < args.length; i++) {
                    if (typeof args[i] === 'string') {
                        console.log(`%c${args[i]}`, cssStyle);
                    } else {
                        console.log(args[i])
                    }
                }
                console.groupEnd()
            } else {
                console.info(
                    colors[styleColors[style]] +
                    this.ts(),
                    ...args,
                    colors.KNRM,
                );
            }
        }
    }

    info(...args: unknown[]) {
        this.printFormatted('base', ...args)
    }

    response(...args: unknown[]) {
        this.printFormatted('response', ...args)
    }

    request(...args: unknown[]) {
        this.printFormatted('request', ...args)
    }

    success(...args: unknown[]) {
        this.printFormatted('success', ...args)
    }

    warning(...args: unknown[]) {
        this.printFormatted('warning', ...args)
    }

    error(...args: unknown[]) {
        this.printFormatted('error', ...args)
    }

    mag(...args: unknown[]) {
        this.printFormatted('mag', ...args)
    }

    ts = () => {
        if (this.printTimestamp) {
            return tsTag() + (this.logUuid ? ' - ' + this.uuid.toUpperCase() + '\n' : (isWebApp ? '\n' : ' '));
        }
        return '';
    };
}

const tsTag = () => '[' + timestamp() + '] ';
const timestamp = () => now().toISOString();
const now = () => new Date();
const colors: StringKeyValueObject = {
    KNRM: '\x1b[0m',
    KRED: '\x1B[31m',
    KGRN: '\x1B[32m',
    KYEL: '\x1B[33m',
    KBLU: '\x1B[34m',
    KMAG: '\x1B[35m',
    KCYN: '\x1B[36m',
    KWHT: '\x1B[37m',
};
const styleColors: StringKeyValueObject = {
    base: 'KWHT',
    request: 'KBLU',
    response: 'KCYN',
    warning: 'KYEL',
    error: 'KRED',
    success: 'KGRN',
    mag: 'KMAG',
}
const defaults = {
    logUuid: false,
    channel: 'console',
    printTimestamp: true,
};

export interface LoggerParams {
    logUuid?: boolean,
    channel?: string,
    printTimestamp?: boolean,
}

export type StyleType = 'base' | 'request' | 'response' | 'warning' | 'error' | 'success' | 'mag';
export default Logger;
