import {ApiJsonResponse, ApplicationType} from "../../Types";
import {createAppSlice} from "../../State/createAppSlice.ts";
import {cancelUserApplication, fetchApplication, fetchApplications, postNewApplication} from "./applicationsAPI.ts";
import Logger from "../../Utils/Logger.ts";
import {PayloadAction} from "@reduxjs/toolkit";
import {ApplicationFormData, ApplicationsSliceState} from "../../Types/ApplicationTypes.ts";

export const initialState: ApplicationsSliceState = {
    isLoading: false,
    error: '',
    data: [],
}

export const applicationsSlice = createAppSlice({
    name: 'applications',
    initialState,
    reducers: (create) => ({
        save: create.reducer((state, action: PayloadAction<ApplicationType>) => {
            for (let i = 0; i < state.data.length; i++) {
                if (state.data[i].id === action.payload.id) {
                    state.data[i] = action.payload;
                }
            }
        }),
        reset: create.reducer((state) => {
            state.isLoading = false;
            state.error = '';
            state.data = []
        }),
        getApplications: create.asyncThunk(
            async (): Promise<ApiJsonResponse<ApplicationType[]>> => {
                // The value we return becomes the `fulfilled` action payload
                return await fetchApplications()
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state, action) => {
                    state.data = action.payload.data
                    state.error = ''
                },
                rejected: (state, action) => {
                    Logger.console("applicationsSlice@getApplications rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
        getApplication: create.asyncThunk(
            async (id: string): Promise<ApiJsonResponse<ApplicationType>> => {
                // The value we return becomes the `fulfilled` action payload
                return await fetchApplication(id)
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state, action) => {
                    state.applicationData = action.payload.data
                    state.error = ''
                },
                rejected: (state, action) => {
                    Logger.console("applicationsSlice@getApplication rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
        createNewApplication: create.asyncThunk(
            async (formData: ApplicationFormData): Promise<ApiJsonResponse<ApplicationType>> => {
                // The value we return becomes the `fulfilled` action payload
                return await postNewApplication(formData)
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state) => {
                    state.error = ''
                },
                rejected: (state, action) => {
                    Logger.console("applicationsSlice@createNewApplication rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
        cancelApplication: create.asyncThunk(
            async (applicationId: string): Promise<ApiJsonResponse<ApplicationType>> => {
                // The value we return becomes the `fulfilled` action payload
                return await cancelUserApplication(applicationId)
            },
            {
                pending: state => {
                    state.isLoading = true
                },
                fulfilled: (state, action) => {
                    state.error = ''
                    for (let i = 0; i < state.data.length; i++) {
                        if (state.data[i].id === action.payload.data.id) {
                            state.data[i] = action.payload.data;
                            i = state.data.length;
                        }
                    }
                },
                rejected: (state, action) => {
                    Logger.console("applicationsSlice@cancelApplication rejected action", action)
                    state.error = action.error?.message || 'Unknown Error'
                },
                settled: (state) => {
                    state.isLoading = false
                },
            },
        ),
    }),
    selectors: {
        selectApplications: applicationsState => applicationsState.data,
        selectApplicationsError: applicationsState => applicationsState.error,
        selectApplicationsIsLoading: applicationsState => applicationsState.isLoading,
    }
})

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
    getApplication,
    getApplications,
    createNewApplication,
    cancelApplication,
    save,
    reset,
} = applicationsSlice.actions
export const {
    selectApplications,
    selectApplicationsError,
    selectApplicationsIsLoading
} = applicationsSlice.selectors
// Export the slice reducer as the default export
// export default userSlice.reducer
