const ApplicationStatus = {
    Pending: 0,
    Approved: 1,
    Rejected: 2,
    Process: 3,
    Vague: 4,
    Cancelled: 5
}
const ApplicationStatusNames = [
    "Pending",
    "Approved",
    "Rejected",
    "Process",
    "Vague",
    "Cancelled"
]
const DocumentType = {
    0: "Passport",
    3: "Resident Permit"
}
const nonTerminalStatuses = [ApplicationStatus.Pending, ApplicationStatus.Process, ApplicationStatus.Vague]
const terminalStatuses = [ApplicationStatus.Approved, ApplicationStatus.Rejected, ApplicationStatus.Cancelled]
export {ApplicationStatus, terminalStatuses, nonTerminalStatuses, ApplicationStatusNames, DocumentType}
