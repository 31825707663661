import {useEffect} from "react";
import {Container} from "react-bootstrap";
import {useSelector} from "react-redux";
import Application from "../../Models/Application/Application";
import Navigator from "../../Models/Navigator/Navigator";
import ReviewPermissions from "../components/ReviewPermissions/ReviewPermissions";
import {useNavigate} from "react-router-dom";
import useTranslate from "../../Hooks/useTranslate";

const VideoLobbyPage = () => {
    const navigate = useNavigate();
    const {t} = useTranslate('translations');
    const application = useSelector(Application.selectApplication);
    const applicationId = useSelector(Navigator.selectApplicationId);

    useEffect(() => {
        if (applicationId) {
            Application.getApplication(applicationId);
            Navigator.setApplicationId("");
        } else if (applicationId === undefined) {
            navigate("/");
        }
    }, [applicationId]);

    useEffect(() => {
        if (application) {
            console.log("application", application);
        }
    }, [application]);

    return (
        <>
            {application && (
                <>
                    {application.isAppointmentDueNow ? (
                        <ReviewPermissions/>
                    ) : (
                        <Container className="m-auto mt-5">
                            <p>
                                {t("The video session cannot start because the appointment is not due until ")}
                                <strong>{application.appointmentTime}</strong>
                            </p>
                            <p>{t("Please check your appointment details and try again later")}</p>
                        </Container>
                    )}
                </>
            )}
        </>
    );
};

export default VideoLobbyPage;
