import HttpClient from "../../Utils/HttpClient.ts";
import {ApiJsonResponse, ApplicationType} from "../../Types";
import {ApplicationFormData, TimeSlotGroups} from "../../Types/ApplicationTypes.ts";

const fetchApplications = async (): Promise<ApiJsonResponse<ApplicationType[]>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.get<Promise<ApiJsonResponse<ApplicationType[]>>>('/application', {body: {}})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const fetchApplication = async (id: string): Promise<ApiJsonResponse<ApplicationType>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.get<Promise<ApiJsonResponse<ApplicationType>>>(`/application/${id}`, {body: {}})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const postNewApplication = async (formData: ApplicationFormData): Promise<ApiJsonResponse<ApplicationType>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<ApplicationType>>>('/application', {body: formData})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const fetchTimeSlots = async (startDate: string, endDate: string): Promise<ApiJsonResponse<TimeSlotGroups>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.get<Promise<ApiJsonResponse<TimeSlotGroups>>>(`/timeslots?startDate=${startDate}&endDate=${endDate}`, {body: {}})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
const cancelUserApplication = async (applicationId: string): Promise<ApiJsonResponse<ApplicationType>> => {
    const httpClient = HttpClient.getInstance()
    if (httpClient) {
        return await httpClient.post<Promise<ApiJsonResponse<ApplicationType>>>(`/application/${applicationId}/cancel`, {body: {}})
    }
    return Promise.reject(new Error(`HttpClient instance not found`))
}
export {fetchApplications, postNewApplication, fetchApplication, fetchTimeSlots, cancelUserApplication}
