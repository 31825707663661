import React from 'react'
import ReactDOM from 'react-dom/client'
import {Provider} from "react-redux"
import App from './App.tsx'
import './index.css'
import {store} from "./State/store.ts";
import i18n from './Utils/Locales/i18n'
import {I18nextProvider} from "react-i18next";
import 'bootstrap/dist/css/bootstrap.min.css';
import "@fontsource/open-sans";
import "@fontsource/open-sans/400.css";

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Provider store={store}>
            <I18nextProvider i18n={i18n}>
                <App/>
            </I18nextProvider>
        </Provider>
    </React.StrictMode>,
)
