import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
import resources from "./index.ts";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const availableLanguages = ['en', 'el'];
i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    // want your translations to be loaded from a professional CDN? => https://github.com/locize/react-tutorial#step-2---use-the-locize-cdn
    // .use(Backend)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    // .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: 'en',
        lng: 'el',
        debug: true,
        initImmediate: false,
        resources,
        supportedLngs: availableLanguages,
        nonExplicitSupportedLngs: true,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        // backend: {
        //   loadPath:
        //     'https://mtuates.northeurope.cloudapp.azure.com/moneygate-core/api/v1-core/locale/file/{{lng}}/{{ns}}',
        //   // '/src/App/Views/common/locales/{{lng}}/{{ns}}.json', // '/locales/{{lng}}/{{ns}}.json',
        //   crossDomain: true,
        // },
        react: {
            // wait: true,
            useSuspense: false,
            bindI18n: 'languageChanged loaded',
        },
        returnObjects: true,
    });


export default i18n;
